.datecontainer{
    height: 90px;
    min-width: 300px;
    max-width: 400px;
    border-radius: 20px;
    box-shadow: 0 0 100px rgba(39, 32, 32, 0.15);

}

.datecontainer-md{
    border-radius: 20px;
    box-shadow: 0 0 100px rgba(39, 32, 32, 0.15);
}