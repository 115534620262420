


.bgScreen{
    display: block;
}
.smScreen{
    display: block;
    width: 40px;
    font-size: 20px;
}



@media screen and (max-width: 468px) {

    .bgScreen{
        display: none;
    }
    .smScreen{
        display: block;
    }
    
}
 
@media screen and (min-width: 468px) {

    .bgScreen{
        display: block;
    }
    .smScreen{
        display: none;
    }
    
}

