body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.navbar{
  animation: fadeInRight 0.8s ease-in-out forwards;
}
.navbar-exit{
  animation: fadeOutRight 0.8s ease-in-out forwards;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(144px);
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    transform: translateX(144px);
    opacity: 0;
  }
}

.init{
  opacity: 0;
  transform: translateX(144px);  
}

@keyframes fadeout {
  0% {
    opacity: 1;
    
  }
  100% {
    
    opacity: 0;
    
  }
  
}

.accordian-animation{
  animation: fadeout 0.5s ease-in-out;
  animation-delay: 0s;

}

.bgtext {
  position: relative;
}

.bgtext:after {
  margin: 3rem;
  content: "Background text";
  position: absolute;
  transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
  color: rgb(187, 182, 182);
  top: 0;
  left: 0;
  z-index: -1;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



