.TimeCard{
    width: 160px;
    height: 150px;
    background-color: #070241;
    color: rgba(0, 0, 0, 0.67);
    font-size: 1.5em;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    opacity: 0.6;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
}
.TimeTitle{
    font-size: 20px;
}

/* For Mobile */
@media only screen and (max-width: 400px) {
    .TimeCard{
        width: 80px;
        height: 75px;
        background-color: #070241;
        color: rgba(0, 0, 0, 0.67);
        font-size: 1.5em;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        opacity: 0.6;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
    }
    .TimeTitle{
        font-size: 10px;
    }
}
